<template>
  <div>
    <div v-if="!error">
      <flixLoader v-if="!data"/>
      <div v-else>
        <validated v-if="!data2.validated || notValidated" :data="data2" :callback="setValidation" :error="function() { error = true }"/>
          <pageHeader :data="data" />
          <navigation :data="data" />
        <div :key="data.validated" style="margin-top: 50px">
          <transition name="flixFadeIn">
            <div :key="updateKey" class="flix-container">
              <component :is="$route.params.page" :data="data" />
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div v-else>
      <error />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    pageHeader () { return import('@/components/bookingInfoPage/header/') },
    navigation () { return import('@/components/bookingInfoPage/navigation') },
    validated () { return import('@/components/bookingInfoPage/header/validated') },
    index () { return import('@/components/bookingInfoPage/components/index') },
    'edit-booking' () { return import('@/components/bookingInfoPage/components/edit') },
    // 'booking-data' () { return import('@/components/bookingInfoPage/components/data') },
    'booking-chat' () { return import('@/components/bookingInfoPage/components/messages') },
    'booking-webinar' () { return import('@/components/bookingInfoPage/webinar/') },
    imprint () { return import('@/components/bookingInfoPage/components/imprint') },
    hitklick () { return import('@/components/bookingInfoPage/components/hitklick/index.vue') },
    blacklist () { return import('@/components/bookingInfoPage/components/blacklist/index.vue') },
    'check-in' () { return import('@/components/bookingInfoPage/components/checkIn/index.vue') },
    'pdf' () { return import('@/components/bookingInfoPage/components/pdf.vue') },
    error () { return import('@/components/bookingInfoPage/error/') }
  },
  props: {},
  data () {
    return {
      data: false,
      data2: false,
      updateKey: new Date().getTime(),
      error: false,
      notValidated: false
    }
  },
  watch: {
    '$route.params' () { this.setUpdateKey() }
  },
  methods: {
    setUpdateKey () {
      this.updateKey = new Date().getTime()
    },
    get_data () {
      this.$flix_post({
        url: 'booking/get_by_id',
        data: {
          user: this.$route.params.user,
          ID: this.$route.params.id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.data = ret.data[1][0]
            this.data2 = JSON.parse(JSON.stringify(ret.data[1][0]))
          } else {
            this.error = true
            // this.$router.push('/')
          }
        }.bind(this)
      })
    },
    setValidation () {
      this.data.validated = 1
      this.get_data()
      this.notValidated = true
    }
  },
  mounted () {
    this.get_data()
    this.$i18n.locale = this.$route.params.lang
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
